import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import { APP_PATHS } from '../constants/paths';
import { Landing } from '../pages/Landing/Landing';

export const Routes = () => (
  <Switch>
    <Route path={APP_PATHS.landing} element={<Landing />} />
    <Route path="*" element={<Navigate to={APP_PATHS.home} replace />} />
  </Switch>
);
