import { Box, useTheme } from '@mui/material';
import { Text } from '../../components/Text/Text';
import { useWindowSize } from '../../hooks/useWindowSize/useWindowSize';

export const Landing = () => {
  const theme = useTheme();
  const { windowSize } = useWindowSize();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        height: windowSize.height,
        width: '100%',
      }}
    >
      <Text text="CRUG" color={theme.palette.secondary.main} />
    </Box>
  );
};
