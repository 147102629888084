import { useEffect, useState } from 'react';

export const useDarkMode = () => {
  const getMode = () =>
    window.matchMedia('(prefers-color-scheme: dark)').matches;

  const [isDarkMode, setIsDarkMode] = useState(getMode());

  useEffect(() => {
    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');

    const handleModeChange = () => {
      setIsDarkMode(getMode());
    };

    handleModeChange();

    matchMedia.addEventListener('change', handleModeChange);

    return () => matchMedia.removeEventListener('change', handleModeChange);
  }, []);

  return { isDarkMode };
};
