import { createTheme } from '@mui/material/styles';
import { typography } from './typography';
import { breakpoints } from './breakpoints';
import { components } from './components';

const defaultTheme = createTheme({
  typography,
  components,
  breakpoints,
  palette: {
    background: {
      default: '#ffffff',
      secondary: '#ffffff',
    },
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#121212',
    },
    tertiary: {
      main: '#f000ff',
    },
    quaternary: {
      main: '#4deeea',
    },
    default: {
      main: '#ffffff',
    },
    error: {
      main: '#d32f2f',
    },
    colors: {
      dark: '#121212',
      light: '#ffffff',
    },
  },
});

export default defaultTheme;
